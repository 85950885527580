import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/index'
import { Container, Row, Col } from 'reactstrap'

class HomeTemplate extends React.PureComponent<{data}> {

  public render() {

    const { indexPage, topFaqs } = this.props.data
    const { frontmatter, html } = indexPage
    const { langKey } = this.props.pageContext;

    return (
      <Layout>
        <Container>
          <Row>
            <Col>
              <h2>Categories</h2>

            </Col>

            <Col>
              <h3>Selected category</h3>
            </Col>
          </Row>

        </Container>
      </Layout>
    )
  }
}

export default HomeTemplate

export const pageQuery = graphql`
  query($path: String!) {
    indexPage:markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    },
    topFaqs: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "faq" }, langKey: { eq: "en" }, categories: { in: "top-faq" } }}
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              path
              langKey
              title
              templateKey
              categories
            }
          }
        }
      }
  }
`